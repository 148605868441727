import axios from 'axios';
import { getField, updateField } from 'vuex-map-fields';
import endPoints from '../../../config/endPoints';
import defaultFilter from './default_pix_filter'
import formatDate from '../../../util/formatDate.js'

export default {
    namespaced: true,
    state: {
        dialog: false,
        dialogExternalUse: false,
        dialogFilter: false,
        editedItem: {},
        filter: {
            used: "",
            start: "",
            end: "",
            value: "",
            endToEndId: "",
            txId: "",
            status: "",
            type_transaction: "",
            id_transaction: "",
        },
        filtered: false,
        items: [],
        itemToDelete: {},
        loading: false,
        mode: 'LIST',
        overlay: false,
        page: 1,
        pagination: {
            page: 1,
            itemsPerPage: 10
        },
        notificationMessage: '',
        notificationType: "success",
        showNotification: false,
        totalItems: 0,
        rowsPerPage: 10
    },
    mutations: {
        loading: (state, payload) => state.loading = payload.show,
        setMode: (state, mode) => state.mode = mode,
        setItems: (state, items) => state.items = items,
        setTotalItems: (state, total) => state.totalItems = total,
        setRowsPerPage: (state, rowsPerPage) => state.rowsPerPage = rowsPerPage,
        showDialog: (state, show) => state.dialog = show,
        showDialogExternalUse: (state, payload) => state.dialogExternalUse = payload.show,
        setPage: (state, payload) => {
            state.pagination.page = payload.page;
            state.pagination.itemsPerPage = payload.itemsPerPage
        },
        setFilter: (state, filter) => {
            state.filter = filter
        },
        setEditedItem: (state, payload) => state.editedItem = payload.item,
        setFiltered: (state, filtered) => state.filtered = filtered,
        showFilter: (state, payload) => state.dialogFilter = payload.show,
        showLoading: (state, show) => state.loading = show,
        showNotification: (state, payload) => {
            state.showNotification = payload.show,
                state.notificationMessage = payload.message,
                state.notificationType = payload.type
        },
        overlay: (state, payload) => {
            state.overlay = payload.show
        },
        viewItem(state, payload) {
            state.editedItem = payload.item
            state.screen = payload.screen
            state.editedIndex = state.items.indexOf(payload.item)
            state.dialog = true
            state.mode = "VIEW"
        },
        updateField
    },
    getters: {
        getField,
    },
    actions: {
        clearFilter: ({ commit, dispatch }) => {
            commit('setFilter', Object.assign({}, defaultFilter))
            commit('setFiltered', false)
            commit('showFilter', { show: false })
            commit('overlay', { show: true })

            setTimeout(function() {
                commit('overlay', { show: false })
                dispatch('getItems', { resetPage: true })
            }, 1000);
        },
        closeDialog: (({ commit, state }) => {
            state.editedItem = {}
            if (state.screen != "messages") {
                commit('message/setSelectedMessages', { messages: [] }, { root: true })
                commit('showDialog', false)
            } else {
                commit('message/showDialogDeposit', { show: false }, { root: true })
            }
            setTimeout(() => {
                commit('closeDialog')
                commit('showErrorNotification', false)
                commit('showSuccessNotification', false)
            }, 300);
        }),
        closeDeleteDialog: ({ commit }) => {
            commit('setDialogDelete', false)
            commit('setItemToDelete', {})
        }, 
        openDialogExternalUse: ({ commit }, payload) => {
            commit('setEditedItem', payload)
            commit('showDialogExternalUse', {show: true})
        },
        pixExternalUse: ({ commit, dispatch, state }) => {
            try {
                commit('overlay', { show: true })
                let url = endPoints.pix24h + '/externalUsed/' + state.editedItem.id;
                let payload = state.editedItem;
                axios.put(url, payload).then(() => {
                    commit('overlay', { show: false })
                    commit('showNotification', { show: true, message: 'Pix utilizado com sucesso', type: 'success' })
                    commit('showDialogExternalUse', { show: false })
                    dispatch('getItems', { resetPage: true })
                    
                }).catch(() => {
                    commit('overlay', { show: false })
                    commit('showNotification', { show: true, message: 'Erro ao utilizar Pix', type: 'error' })
                })
            } catch (error) {
                commit('overlay', { show: false })
                commit('showNotification', { show: true, message: 'Erro ao utilizar Pix', type: 'error' })
            }
        },
        getItems: ({ commit, state }, payload) => {
            commit('loading', { show: true })
            let url = endPoints.pix24h;
            let filter = state.filter;

            if (payload.resetPage === true) {
                url += '?page=' + 1 + '&' + 'limit=' + 10;
                commit('setPage', { page: 1, itemsPerPage: 10 })
            } else {
                url += '?page=' + state.pagination.page + '&' + 'limit=' + state.pagination.itemsPerPage;
            }

            commit('setFiltered', false)
            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined && filter[field] !== null) {    
                    commit('setFiltered', true)

                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])
                        url += "&" + field + "=" + formatDate(e) + " " + e.toLocaleTimeString()
                    } else if(field == 'used') {
                        url += "&used"
                        url += "=" + (filter[field] == 1 ? 'true' : 'false')
                        console.log(url)
                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            if (state.pagination && state.pagination.sortBy && state.pagination.sortBy.length > 0) {
                url += "&sort_by=" + state.pagination.sortBy[0]
                url += "&sort_desc=" + state.pagination.sortDesc[0]
            }
            axios.get(url).then(items => {
                console.log(items.data.data)
                let data = items.data
                commit('setTotalItems', data.total)
                commit('loading', { show: false })
                commit('setItems', data.data)
            });
        },
        getItemsNoPage: ({ commit, state }) => {
            commit('overlay', { show: true })
            let url = endPoints.pix;
            let filter = state.filter;
            url += '?page=' + '1' + '&' + 'itemsPerPage=' + '99999';

            Object.keys(filter).forEach(function(field) {
                if (filter[field] !== "" && filter[field] !== undefined) {
                    if (field == "end" || field == "start") {
                        var e = new Date(filter[field])

                        url += "&" + field + "=" + e.toISOString().substr(0, 10) + " " + e.toLocaleTimeString()

                    } else {
                        url += "&" + field + "=" + filter[field]
                    }
                }
            });
            return axios.get(url).then(items => {
                commit('overlay', { show: false })
                return items.data.data
            }).catch(() => {
                commit('overlay', { show: false })
            });
        },
        getUsers: ({ commit }, payload) => {
            let url = endPoints.users;
            url += '/attendants/' + payload.search

            axios.get(url).then(users => {
                commit('setUsers', { users: users.data })
            });
        },
        showDeleteDialog: ({ commit }, item) => {
            commit('setDialogDelete', true)
            commit('setItemToDelete', item)
        },
        viewItem: ({ commit }, payload) => {
            if (payload.item.pix_id) {
                axios.get(endPoints.pix + "/" + payload.item.pix_id).then((response) => {
                    let pix = response.data
                    commit('setPix', { pix: pix })
                    commit('viewItem', payload)
                }).catch(() => {
                    commit('viewItem', payload)
                })
            } else {
                commit('viewItem', payload)
            }
        },
    }
}