<template>
    <v-card class="contact-card-description">
        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 sm12 md12>
                        <v-avatar v-if="avatar" size="40" class="avatar-header">
                            <v-img v-if="avatar" :src="avatar"></v-img>
                        </v-avatar>
                        <v-card-title>
                            {{ contact_name ? contact_name : contact_id }}
                            <v-divider></v-divider>
                            {{ contact_id }}
                        </v-card-title>
                    </v-flex>

                    <v-flex
                        xs12
                        sm12
                        md12
                        v-if="checkProfileAcessWithdrawalLeader()"
                    >
                        <v-textarea
                            auto-grow
                            :append-icon="
                                mode == 'VIEW' ? 'mdi-pencil' : 'mdi-check'
                            "
                            @click:append="
                                mode == 'VIEW'
                                    ? (mode = 'EDIT')
                                    : saveContactDescriptionChange({
                                          screen: screen,
                                      })
                            "
                            label="Descrição"
                            background-color="white"
                            outlined
                            id="textareadesc"
                            :readonly="mode == 'VIEW'"
                            hide-details="true"
                            v-model="contact_description"
                            :value="contact_description"
                            v-on:keyup.enter="checkEnter('contact_description')"
                        >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 sm12 md12 v-else>
                        <v-textarea
                            auto-grow
                            label="Descrição"
                            background-color="white"
                            outlined
                            id="textareadesc"
                            :readonly="mode == 'VIEW'"
                            hide-details="true"
                            v-model="contact_description"
                            :value="contact_description"
                            v-on:keyup.enter="checkEnter('contact_description')"
                        >
                        </v-textarea>
                    </v-flex>

                    <v-flex cols="12" sm="12" v-if="screen == 'chat'">
                        <v-chip
                            active-class=""
                            v-for="chip in chipsList"
                            class="ma-2"
                            :key="chip.id"
                            @click="changeStatus(chip)"
                            :color="getColorChip(chip)"
                            :text-color="chip.status == 1 ? 'white' : 'black'"
                        >
                            {{ chip.label }}
                        </v-chip>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                        <v-checkbox v-if="contact_id.length > 13"
                            required
                            label="Enviar Comprovante"
                            v-model="sendReceipt"
                            outlined
                            :readonly="mode == 'VIEW'"
                            :disabled="mode == 'VIEW'"
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm12 md12>
                        <v-card-title>Anotados</v-card-title>
                    </v-flex>
                    <v-flex
                        xs12
                        sm4
                        md4
                        v-if="checkProfileAcessWithdrawalLeader()"
                    >
                        <v-text-field
                            required
                            label="Alterar Anotados"
                            v-model="changed_value"
                            :readonly="mode == 'VIEW'"
                            :append-icon="
                                mode == 'VIEW' ? 'mdi-pencil' : 'mdi-check'
                            "
                            @click:append="
                                mode == 'VIEW'
                                    ? (mode = 'EDIT')
                                    : saveContactDescriptionChange({
                                          screen: screen,
                                      })
                            "
                            :error-messages="errors.collect('changed_value')"
                            v-validate="'decimal:2'"
                            data-vv-name="changed_value"
                            outlined
                            :disabled="this.openedChat.block_annotated"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4 v-else>
                        <v-text-field
                            required
                            label="Alterar Anotados"
                            v-model="changed_value"
                            :readonly="mode == 'VIEW'"
                            :error-messages="errors.collect('changed_value')"
                            v-validate="'decimal:2'"
                            data-vv-name="changed_value"
                            outlined
                            :disabled="this.openedChat.block_annotated"
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                        <v-text-field
                            required
                            label="Pagamento Pendente *"
                            v-model="pending_payment"
                            :readonly="true"
                            outlined
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                        <v-text-field
                            required
                            label="Semana Atual *"
                            v-model="current_week"
                            :readonly="true"
                            outlined
                        ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm9 md9>
                        <v-text-field
                            required
                            label="Link de Pagamento"
                            v-model="urlCompleta"
                            :readonly="true"
                            outlined
                        ></v-text-field>
                    </v-flex>

                    <v-flex xs6 sm1 md1 my-3>
                        <v-icon
                            large
                            color="black"
                            v-if="hash_contact_id"
                            @click="copyText"
                            >mdi-content-copy</v-icon
                        >
                    </v-flex>

                    <v-flex xs12 sm4 md4 v-if="mode != 'VIEW'">
                        <v-select
                            required
                            label="Tipo de Anotado *"
                            v-model="origin"
                            :items="annotated_types"
                            :readonly="mode == 'VIEW'"
                            :error-messages="errors.collect('origin')"
                            v-validate="'required'"
                            data-vv-name="origin"
                            item-text="description"
                            item-value="id"
                            outlined
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 sm8 md8 v-if="mode != 'VIEW'">
                        <v-text-field
                            required
                            label="Motivo da Alteração Manual *"
                            v-model="description"
                            :readonly="mode == 'VIEW'"
                            :error-messages="errors.collect('description')"
                            data-vv-name="description"
                            outlined
                        ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md12 v-if="showAddTo">
                        <v-select
                            :items="types_add_to"
                            v-if="changed_value > 0"
                            label="Adicionar a(o)"
                            outlined
                            v-model="add_to"
                            item-text="description"
                            item-value="id"
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 sm12 md12 v-if="showAddTo">
                        <v-select
                            :items="types_add_to"
                            v-if="
                                changed_value != '' &&
                                !(parseFloat(changed_value) >= 0)
                            "
                            label="Abater do(a)"
                            outlined
                            v-model="discount_from"
                            item-text="description"
                            item-value="id"
                        ></v-select>
                    </v-flex>
                    <v-flex
                        xs12
                        sm12
                        md12
                        v-if="checkProfileAcessWithdrawalLeader()"
                    >
                        <v-textarea
                            auto-grow
                            :append-icon="
                                mode == 'VIEW' ? 'mdi-pencil' : 'mdi-check'
                            "
                            @click:append="
                                mode == 'VIEW'
                                    ? (mode = 'EDIT')
                                    : saveContactDescriptionChange({
                                          screen: screen,
                                      })
                            "
                            label="Anotados - Observação"
                            background-color="white"
                            outlined
                            id="textareadesc"
                            :readonly="mode == 'VIEW'"
                            hide-details="true"
                            v-model="observation"
                            :value="observation"
                            v-on:keyup.enter="checkEnter('annotated')"
                        >
                        </v-textarea>
                    </v-flex>
                    <v-flex xs12 sm12 md12 v-else>
                        <v-textarea
                            auto-grow
                            label="Anotados - Observação"
                            background-color="white"
                            outlined
                            id="textareadesc"
                            :readonly="mode == 'VIEW'"
                            hide-details="true"
                            v-model="observation"
                            :value="observation"
                            v-on:keyup.enter="checkEnter('annotated')"
                        >
                        </v-textarea>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
        <v-card-actions v-if="this.mode == 'EDIT' && this.screen == 'chat'">
            <v-col>
                <v-btn
                    text
                    @click="
                        showDialogContactDescription({
                            show: false,
                            annotated: '',
                            contact_description: '',
                            chips_contact: '',
                        })
                    "
                    >Cancelar</v-btn
                >
            </v-col>
            <v-col class="text-center" v-if="showButtonIgnoreExists">
                <v-btn
                    color="primary"
                    @click="
                        saveContactDescriptionChange({
                            screen: screen,
                            check_exists: false,
                        })
                    "
                    >Confirmar</v-btn
                >
            </v-col>
            <v-col class="text-right">
                <v-btn
                    text
                    type="submit"
                    right
                    @click="saveContactDescriptionChange({ screen: screen })"
                    >Salvar</v-btn
                >
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    computed: {
        ...mapFields('chat', [
            'contact_annotated.changed_value',
            'contact_annotated.description',
            'contact_annotated.origin',
            'contact_annotated.current_week',
            'contact_annotated.discount_from',
            'contact_annotated.pending_payment',
            'contact_annotated.observation',
            'contact_annotated.new_annotated',
            'contact_annotated.add_to',
            'old_annotated',
            'contact_description',
            'chips_contact',
            'sendReceipt',
        ]),
        ...mapState('login', ['userData']),
        ...mapState('chat', ['possible_origins', 'showButtonIgnoreExists']),
        chipsList() {
            return this.mode == 'VIEW'
                ? this.chips_contact.filter((c) => c.status == 1)
                : this.chips_contact
        },
        showAddTo() {
            let roles = [2, 5, 80, 81, 97, 98, 99]
            return roles.indexOf(this.userData.role) != -1
        },
        urlCompleta() {
            if (this.hash_contact_id) {
                return this.url_base_link + this.hash_contact_id
            } else {
                return ''
            }
        },
    },
    props: [
        'avatar',
        'contact_id',
        'contact_name',
        'hash_contact_id',
        'generate_hash',
        'description',
        'screen',
        'start_mode',
        'showContactDescription',
        'openedChat',
    ],
    created() {
        this.mode = this.start_mode
        if (!this.hash_contact_id) {
            this.generate(this.contact_id)
        }
    },
    data: () => ({
        annotated_types: [
            { id: 4, description: 'Correção de Fechamento' },
            { id: 5, description: 'Correção de Saldo Incorreto' },
            { id: 6, description: 'Correção de Valor Lançado Incorretamente' },
            { id: 7, description: 'Multa Suprema' },
        ],
        mode: '',
        types_add_to: [
            { id: 0, description: 'Semana Atual' },
            { id: 1, description: 'Pagamento Pendente' },
        ],
        url_base_link: 'https://anotados.flutterflow.app/annotatedPage?hash=',
    }),
    methods: {
        ...mapGetters('login', ['checkProfileAcessWithdrawalLeader']),
        ...mapMutations('chat', [
            'showDialogContactDescription',
            'showNotification',
        ]),
        ...mapActions('chat', ['saveContactDescriptionChange', 'generateHash']),
        checkEnter(field) {
            if (!event.ctrlKey && !event.shiftKey) {
                if (this.mode == 'EDIT') {
                    event.preventDefault()
                    this[field] = this[field].substr(0, this[field].length - 1)
                    this.saveContactDescriptionChange({ screen: this.screen })
                }
            } else {
                this[field] = this[field] + '\n'
            }
        },
        copyText() {
            let text = this.urlCompleta
            navigator.clipboard.writeText(text).then(
                () => {
                    let notification = {
                        show: true,
                        message: 'Link copiado para área de transferência',
                        type: 'success',
                    }
                    this.showNotification(notification)
                },
                function (err) {
                    console.error('Async: Could not copy text: ', err)
                    let notification = {
                        show: true,
                        message: 'Erro ao copiar o link',
                        type: 'error',
                    }
                    this.showNotification(notification)
                },
            )
        },
        async generate(contact_id) {
            let hash = await this.generateHash(contact_id)
            this.$emit('updateProp', hash)
        },
        changeStatus(chip) {
            if (this.mode == 'EDIT') {
                if (chip.status == 0) {
                    chip.status = 1
                } else {
                    chip.status = 0
                }
            }
        },
        getColorChip(chip) {
            if (chip.status == 1) {
                if (chip.id == 1) {
                    return 'orange'
                } else if (chip.id == 2) {
                    return 'green'
                } else if (chip.id == 3) {
                    return 'blue'
                } else if (chip.id == 4) {
                    return 'red'
                } else if (chip.id == 5) {
                    return 'purple'
                } else if (chip.id == 9 || chip.id == 8) {
                    return 'yellow'
                } else {
                    return 'black'
                }
            }
        },
    },
    watch: {
        showContactDescription: function () {
            if (this.showContactDescription == false) {
                this.mode = 'VIEW'
            } else {
                if (this.generate_hash) {
                    this.generate(this.contact_id)
                }
            }
        },
    },
}
</script>

<style>
@import './css/contact_description.css';
</style>
